import { render, staticRenderFns } from "./FeaturesEn.vue?vue&type=template&id=f5918ec8&"
import script from "./FeaturesEn.vue?vue&type=script&lang=js&"
export * from "./FeaturesEn.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesEn.vue?vue&type=style&index=0&id=f5918ec8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports