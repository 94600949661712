<template>
    <ServicePage  />
</template>

<script>
import ServicePage from '../components/En/Services/ServicePage.vue'
export default {
    name: "Service",
    components: { 
        ServicePage,
    },
}
</script>

<style>

</style>
