import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";

import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
})


